.imprimir{
  background: #dadce0;
}

.dialogProduto .modal-dialog {
  max-width: 900px;
}


form .ng-invalid:not(form):not(p-inputMask):not(p-radiobutton):not(kendo-colorpicker):not(p-dropdown):not(.ng-pristine):not([type=radio]):not([type=checkbox]):not(kendo-switch) {
  border-left: 5px solid #f1556c;
}

form .ng-valid:not(form):not(.ng-dirty):not(p-inputMask):not(kendo-colorpicker):not(p-radiobutton):not(p-dropdown):not(kendo-datepicker):not([type=radio]):not([type=checkbox]):not(kendo-switch) {
  border-left:   5px solid rgb(86, 169, 18);
}

form .ng-dirty.ng-valid:not(form):not(p-inputMask):not(kendo-colorpicker):not(p-radiobutton):not(p-dropdown):not(kendo-datepicker):not([type=radio]):not([type=checkbox]):not(kendo-switch):not(app-controle-editor-mensagem) {
  border-left: 5px solid rgb(86, 169, 18);
}

.k-radio, .k-checkbox {
  border-color: rgba(0, 0, 0, 0.2);
}

.k-radio:hover, .k-checkbox:hover {
  border-color: rgba(0, 0, 0, 0.4);
}

.k-radio:checked, .k-checkbox:checked {
  border-color: #4f229c !important;
  color: #ffffff !important;
  background-color: #7e57c2 !important;
}

.k-radio:checked:focus, .k-checkbox:checked:focus {
  border-color: #663fa8 !important;
  box-shadow: 0 0 0 2px rgba(120, 80, 191, 0.3) !important;
}

.k-switch {
  width: 6.2em;
}

.form-control:disabled, .form-control[readonly] {
  background: #e9ecef !important;
}

.k-textbox {
  height: calc(1.4285714286em + 15px);
}

.k-textbox .k-button-clear {
  height: calc(1.4285714286em + 13px);
}

.k-radio + .k-radio-label {
  margin-left: 0px !important;
}

kendo-label.k-radio-label > .k-label:first-child {
  padding-left: 8px !important;
}

.k-tabstrip > .k-content {
  padding: 12px !important;
  border-top: none;
}

.k-tabstrip-items-wrapper {
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}

.k-tabstrip-items-wrapper .k-item.k-state-active, .k-tabstrip-items-wrapper .k-item.k-state-selected {
  margin-bottom: -1px !important;
  border-bottom: none;
}

.k-tabstrip-scrollable .k-tabstrip-items-wrapper {
  display: flex;
  flex-direction: row;
}

.k-tabstrip .k-disabled {
  color: #ccc;
}

.k-tabstrip-scrollable .k-tabstrip-items {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  top: 1px;
}

.bsModal{
  margin-top: 30px;
}

.bsModal .k-window-titlebar{
  background: #3a44b9 !important;
}

.bsModal .k-window-titlebar  h4, .bsModal .k-window-titlebar  h3{
  color: #fff !important;
}

.bsModal .k-window{
  border-radius: 5px !important;
}

.bsModal .k-dialog-content{
  padding: 0 !important;
}

.borda-redonda{
  border-radius: 5px !important;
}

.padding0{
  padding: 0 !important;
}

.form-group .k-datepicker, .form-group .k-timepicker, .form-group .k-combobox {
    padding: 0px !important;
}

.form-control.k-widget {
  padding: 0px !important;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
