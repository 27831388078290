.was-validated .ng-invalid ~ .invalid-feedback{
  display: block !important;
}

.was-validated.ng-invalid .invalid-feedback{
  display: block !important;
}

.invalid-feedback p{
  padding: 0; margin:0;
}
.badge-success{
  background-color:#35ce4c;
}

.badge-danger{
  background-color:#f44e5d;
}
.text-success {
  color: #6db31b !important;
}


.btn-info{
  color: #fff;
  background-color: #4751be;
  border-color: #3a44b9;
}
.btn-info:hover{
  background-color: #2f459bbd;
  border-color: #0d2dab2b;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  background-color: #6db31b;
  border-color: #6db31b;
}
.btn-success:not(:disabled):not(.disabled), .btn-success:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle {
  background-color: #6db31b;
  border-color: #6db31b;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.15rem rgba(109, 178, 27, 0.42);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(109, 178, 27, 0.42);
}

body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0.56em;
}

.form-control .k-clear-value {
  top: 3px !important;
}

.k-radio, .k-checkbox {
  border-color: rgba(0, 0, 0, 0.30);
  width: 18px;
  height: 18px;
}

.k-checkbox {
  border-radius: 2px;
}
.k-radio-label::before {
  border-color: rgba(0,0,0,0.3);
  background-color: #fff;
}

.k-checkbox:checked + .k-checkbox-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.k-radio:checked + .k-radio-label::before  {
  background-color: #007bff;
  border-color: transparent;
  box-shadow: 0 0 1px 0 #007bff inset;
}

.autocompleteContatos .k-nodata {
  display: none;
}

.coluna_menor {
  font-size: 13px;
}

.coluna_menor span {
  font-size: 13px;
}

/*
.k-combobox .k-dropdown-wrap .k-input ,
.k-multiselect .k-multiselect-wrap .k-input,
.k-dropdowntree .k-multiselect-wrap .k-input ,
.k-numerictextbox .k-numeric-wrap .k-input ,
.k-dropdown .k-dropdown-wrap .k-input,
.k-dateinput .k-dateinput-wrap .k-input,
.k-dateinput .k-picker-wrap .k-input,
.k-datepicker .k-picker-wrap .k-input,
.k-datetimepicker .k-picker-wrap .k-input,
.k-dropdowntree .k-dropdown-wrap .k-input ,
.k-datepicker .k-dateinput-wrap .k-input,
.k-timepicker .k-dateinput-wrap .k-input,
.k-timepicker .k-picker-wrap .k-input ,
.k-maskedtextbox.form-control .k-textbox ,
.k-input {
  height: calc(1.5em + 0.9rem );
  padding: 0.45rem 0.9rem;
  line-height: 1.5;
}

 */

/*
.k-combobox.form-control,
.k.multi-select.form-control,
.k-numeric-wrap.form-control,
.k-maskedtextbox.form-control,
.k-numerictextbox.form-control,
.k-widget.form-control:not(.k-autocomplete){
  border: none !important;
}
  */

.k-dateinput .k-dateinput-wrap ,
.k-numerictextbox .k-numeric-wrap,
.k-datetimepicker .k-dateinput-wrap,
.k-multiselect  .k-multiselect-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap,
.k-multiselect-wrap,
.k-combobox .k-dropdown-wrap,
.k-flatcolorpicker .k-color-value,
.k-autocomplete,
.k-textbox,
.k-textarea{
  border-color: #ced4da;
}

.abreviar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cpointer{
  cursor: pointer;
}

.content-page {
  margin-bottom: 58px;
}

.k-toolbar {
  overflow: inherit;
}


.k-tabstrip-items-wrapper .k-item {
  color: #0b0b0b;
}

.k-tabstrip-items-wrapper .k-item.k-state-active, .k-tabstrip-items-wrapper .k-item.k-state-selected {
  font-weight: bold;
}

.k-maskedtextbox.form-control .k-textbox {
  height: calc(2.875rem + 2px) !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.3rem !important;
}
